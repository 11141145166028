import L from "leaflet";

//import  SimulatedData  from '../../data/togo_eclairage_base_calcul.json';
//import { useSelector } from 'react-redux';

/*
function ResultData(){
  const resultData = useSelector((state) => state.layersSimulated.value);
  return resultData;
}
*/
export const dataLoadInfos = [
  /*{
    dataUrl: "/local_db/simulation_calcul/togo_export_site_15000.geojson",
    fieldName: "frontiereKM0Info",
  },*/
  
  {
    dataUrl: "/local_db/togo_eclairage_base_calcul.geoJSON",
    fieldName: "eclairageCalculInfo",
  },
  {
    dataUrl: "/local_db/togo_eclairage_existant.geojson",
    fieldName: "frontiereKM0Info",
  },
  {
    dataUrl: "/local_db/frontiere_diametre/togo_frontiere_1000.geojson",
    fieldName: "frontiereKM1Info",
  },
  {
    dataUrl: "/local_db/frontiere_diametre/togo_frontiere_2000.geojson",
    fieldName: "frontiereKM2Info",
  },
  {
    dataUrl: "/local_db/frontiere_diametre/togo_frontiere_3000.geojson",
    fieldName: "frontiereKM3Info",
  },
  {
    dataUrl: "/local_db/frontiere_diametre/togo_frontiere_4000.geojson",
    fieldName: "frontiereKM4Info",
  },
  {
    dataUrl: "/local_db/frontiere_diametre/togo_frontiere_5000.geojson",
    fieldName: "frontiereKM5Info",
  },
  {
    dataUrl: "/local_db/frontiere_diametre/togo_frontiere_6000.geojson",
    fieldName: "frontiereKM6Info",
  },
  {
    dataUrl: "/local_db/frontiere_diametre/togo_frontiere_7000.geojson",
    fieldName: "frontiereKM7Info",
  },{
    dataUrl: "/local_db/frontiere_diametre/togo_frontiere_8000.geojson",
    fieldName: "frontiereKM8Info",
  },
  {
    dataUrl: "/local_db/frontiere_diametre/togo_frontiere_9000.geojson",
    fieldName: "frontiereKM9Info",
  },
  {
    dataUrl: "/local_db/frontiere_diametre/togo_frontiere_10000.geojson",
    fieldName: "frontiereKM10Info",
  }
 
];


/*
 * Colors for the existant and the simulation Map
 *
 */
 /*
let defaultStyle = {
    fillcolor: "#dcdcdc",
    fillOpacity: 1,

    color: " #FFF",
    weight: 0.4,
    opacity: 0.7,
};
*/
let firstStyle = {
    fillColor: "#ffffe5",
    fillOpacity: 1,

    color: "#dcdcdc",
    weight: 0.4,
    opacity: 0.7,
};

let secondStyle = {
    fillColor: "#fff7bc",
    fillOpacity: 1,

    color: "#dcdcdc",
    weight: 0.4,
    opacity: 0.7,
};

let thirdStyle = {
    fillColor: "#fee391",
    fillOpacity: 1,

    color: "#dcdcdc",
    weight: 0.4,
    opacity: 0.7,
};

let fourthStyle = {
    fillColor: "#fec44f",
    fillOpacity: 1,

    color: "#dcdcdc",
    weight: 0.4,
    opacity: 0.7,
};


let fithStyle = {
    fillColor: "#fe9929",
    fillOpacity: 1,

    color: "#dcdcdc",
    weight: 0.4,
    opacity: 0.7,
};


let sixthStyle = {
    fillColor: "#ec7014",
    fillOpacity: 1,

    color: "#dcdcdc",
    weight: 0.4,
    opacity: 0.7,
};

let seventhStyle = {
    fillColor: "#cc4c02",
    fillOpacity: 1,

    color: "#dcdcdc",
    weight: 0.4,
    opacity: 0.7,
};

let eigthStyle = {
    fillColor: "#8c2d04",
    fillOpacity: 1,
    
    color: "#dcdcdc",
    weight: 0.4,
    opacity: 0.7,
};



/*
 * Colors for the difference Map
 *
 */
let zeroStyleDiff = {
    fillcolor: "#6F2DA8",
    fillOpacity: 1,

    color: " #dcdcdc",
    weight: 0.4,
    opacity: 0.7,
};

let firstStyleDiff = {
    fillColor: "#005a32",
    fillOpacity: 1,

    color: "#dcdcdc",
    weight: 0.4,
    opacity: 0.7,
};

let secondStyleDiff = {
    fillColor: "#238443",
    fillOpacity: 1,

    color: "#dcdcdc",
    weight: 0.4,
    opacity: 0.7,
};

let thirdStyleDiff = {
    fillColor: "#41ab5d",
    fillOpacity: 1,

    color: "#dcdcdc",
    weight: 0.4,
    opacity: 0.7,
};

let fourthStyleDiff = {
    fillColor: "#78c679",
    fillOpacity: 1,

    color: "#dcdcdc",
    weight: 0.4,
    opacity: 0.7,
};


let fithStyleDiff = {
    fillColor: "#addd8e",
    fillOpacity: 1,

    color: "#dcdcdc",
    weight: 0.4,
    opacity: 0.7,
};


let sixthStyleDiff = {
    fillColor: "#d9f0a3",
    fillOpacity: 1,

    color: "#dcdcdc",
    weight: 0.4,
    opacity: 0.7,
};

let seventhStyleDiff = {
    fillColor: "#f7fcb9",
    fillOpacity: 1,

    color: "#dcdcdc",
    weight: 0.4,
    opacity: 0.7,
};

let eigthStyleDiff = {
    fillColor: "#ffffe5",
    fillOpacity: 1,
    
    color: "#dcdcdc",
    weight: 0.4,
    opacity: 0.7,
};


export const FrontiereLayers = {
  slug: "existant",
  forceUpdate: true,
  addToMap: (map, dataSource, layerSelectionButtons) => {
    //TBM really not clear, why we do dataSource.educationInfo --> need change
    const { kmDiametre } = layerSelectionButtons;
    //console.log(kmDiametre)
    const data = dataSource[kmDiametre];
    let myLayer = L.geoJSON(data, {
      pointToLayer: function (feature, latlng) {

        //TBM?
        // instead of using a filter, just display non-selected items in grey
        
      },
      onEachFeature: function (feature, layer) {
        layer
          .bindPopup(
              "Type de zone:  " +
              feature.properties["type"] +
              "<br>" +
              "Canton:  " +
              feature.properties["canton_nom"] +
              "<br>" +
              "Préfecture :  " +
              feature.properties["prefecture_nom"] +
              "<br>" +
              "Population (estimation) :  " +
              feature.properties["popCouv"] +
              "<br>" +
              "Lampadaires Existants :  " +
              feature.properties["lampCouv"] +
              "<br>"+
              "Ecoles à électrifier :  " +
              feature.properties["ecoleCouv"] +
              "<br>"+
              "Etablissements santé à électrifier :  " +
              feature.properties["santeCouv"] +
              "<br>"+
              "Points d'eau à électrifier :  " +
              feature.properties["santeCouv"] +
              "<br>"+
              "Ratio actuel :  " +
              feature.properties["hab_lamp"]  +
              "<br>"
          )
          .addTo(map);
          
      },
      style: function (feature) {
        //if ( firstLayer === "existant" ) {
          let nbr_habit = feature.properties["hab_lamp"];
          if (nbr_habit>=0 && nbr_habit< 20) {
            return firstStyle;
          }
          if (nbr_habit>=20 && nbr_habit< 40) {
            return secondStyle;
          }
          if (nbr_habit>=40 && nbr_habit<60) {
            return thirdStyle;
          }
          if (nbr_habit>=60 && nbr_habit<100) {
            return fourthStyle;
          }
          if (nbr_habit>=100 && nbr_habit<150) {
            return fithStyle;
          }
          if (nbr_habit>=150 && nbr_habit< 200) {
            return sixthStyle;
          }
          if (nbr_habit>=200 && nbr_habit< 400) {
            return seventhStyle;
          }
          if (nbr_habit>=400) {
            return eigthStyle;
          }
         
        //}

      }
    });
    myLayer.addTo(map);
    return myLayer;
  },
};




export const FrontiereSimulatedLayers = {
  slug: "calculated",
  forceUpdate: true,
  addToMap: (map, dataSource, layerSelectionButtons) => {
    //TBM really not clear, why we do dataSource.educationInfo --> need change

    const { simulatedInfo,simulatedData } = layerSelectionButtons;
    if(simulatedInfo === "nouveau" || simulatedInfo ==="difference"){

      //console.log(simulatedData)
    const data = simulatedData;// SimulatedDataOnCantons.features;
    let myLayer = L.geoJSON(data, {
      pointToLayer: function (feature, latlng) {

        //TBM?
        // instead of using a filter, just display non-selected items in grey
        
      },
      onEachFeature: function (feature, layer) {
        if ( simulatedInfo === "nouveau" ) {
          //let totalStreetLight = feature.properties["lamp_construire"] > feature.properties["lampCouv"] ?  feature.properties["lamp_construire"] : feature.properties["lampCouv"];
        layer
          .bindPopup(
            "Canton:  " +
              feature.properties["canton_nom"] +
              "<br>" +
              "Préfecture :  " +
              feature.properties["prefecture_nom"] +
              "<br>" +
              "Population (estimation):  " +
              feature.properties["popCouv"] +
              "<br>" +
              "Lampadaires Existants :  " +
              feature.properties["lampCouv"] +
              "<br>"+
              "Ecoles à électrifier :  " +
              feature.properties["ecoleCouv"] +
              "<br>"+
              "Etablissements santé à électrifier :  " +
              feature.properties["santeCouv"] +
              "<br>"+
              "Points d'eau à électrifier :  " +
              feature.properties["santeCouv"] +
              "<br>"+
              "Ratio actuel :  " +
              feature.properties["hab_lamp"]  + " hab/lamp "+
              "<br>"+
              "_______________________________" +
              "<br>"+
              /*"Ratio pris en compte:  " +
              feature.properties["ratio_pris_en_compte"]  +
              "<br>"+*/
              "Nouveaux Lampadaires à construire :  " +
              feature.properties["lamp_nov"]  +
              "<br>"+
              "Nouveau total Lampadaires:  " + feature.properties["lamp_construire"] +
              "<br>"+
              "Nouveau ratio:  " +
              feature.properties["ratio_pris_en_compte"] 
                + " hab/lamp "+              
              "<br>"
          )
          .addTo(map);
          }else{
          //let totalStreetLight = feature.properties["lamp_construire"] > feature.properties["lampCouv"] ?  feature.properties["lamp_construire"] : feature.properties["lampCouv"];
            layer
          .bindPopup(
            "Canton:  " +
              feature.properties["canton_nom"] +
              "<br>" +
              "Préfecture :  " +
              feature.properties["prefecture_nom"] +
              "<br>" +
              "Population (estimation) :  " +
              feature.properties["popCouv"] +
              "<br>" +
              "Lampadaires Existants :  " +
              feature.properties["lampCouv"] +
              "<br>"+
              "Ecoles à électrifier :  " +
              feature.properties["ecoleCouv"] +
              "<br>"+
              "Etablissements santé à électrifier :  " +
              feature.properties["santeCouv"] +
              "<br>"+
              "Points d'eau à électrifier :  " +
              feature.properties["santeCouv"] +
              "<br>"+
              "Ratio actuel :  " +
              feature.properties["hab_lamp"]  + " hab/lamp "+
              "<br>"+
              "_______________________________" +
              "<br>"+
              "Nouveaux Lampadaires à construire :  " +
              feature.properties["lamp_nov"]  +
              "<br>"+
              "Nouveau total Lampadaires:  " + feature.properties["lamp_construire"] +
              "<br>"+
              "Nouveau ratio:  " +
              feature.properties["ratio_pris_en_compte"] 
                + " hab/lamp "+              
              "<br>"+
              "Taux d'augmentation du nombre de lampadaires :  " +
              Math.round(feature.properties["lamp_diff"])  +"%"+ 
              "<br>"
          )
          .addTo(map);
          }
      },
      style: function (feature) {
          let nbr_habit = -1;
          if ( simulatedInfo === "nouveau" ) {
             nbr_habit= feature.properties["ratio_pris_en_compte"];
             if (nbr_habit>=0 && nbr_habit< 20) {
                return firstStyle;
              }
              if (nbr_habit>=20 && nbr_habit< 40) {
                return secondStyle;
              }
              if (nbr_habit>=40 && nbr_habit<60) {
                return thirdStyle;
              }
              if (nbr_habit>=60 && nbr_habit<100) {
                return fourthStyle;
              }
              if (nbr_habit>=100 && nbr_habit<150) {
                return fithStyle;
              }
              if (nbr_habit>=150 && nbr_habit< 200) {
                return sixthStyle;
              }
              if (nbr_habit>=200 && nbr_habit< 400) {
                return seventhStyle;
              }
              if (nbr_habit>=400) {
                return eigthStyle;
              }
          }
          if ( simulatedInfo === "difference" ) {

              let nbrNewLamp= feature.properties["lamp_nov"];
              if (nbrNewLamp>=0 && nbrNewLamp< 1) {
                return zeroStyleDiff;
              }
              if (nbrNewLamp>=1 && nbrNewLamp< 20) {
                return eigthStyleDiff;
              }
              if (nbrNewLamp>=20 && nbrNewLamp< 40) {
                return seventhStyleDiff;
              }
              if (nbrNewLamp>=40 && nbrNewLamp<60) {
                return sixthStyleDiff;
              }
              if (nbrNewLamp>=60 && nbrNewLamp<100) {
                return fithStyleDiff;
              }
              if (nbrNewLamp>=100 && nbrNewLamp<200) {
                return fourthStyleDiff;
              }
              if (nbrNewLamp>=200 && nbrNewLamp< 400) {
                return thirdStyleDiff;
              }
              if (nbrNewLamp>=400 && nbrNewLamp< 500) {
                return secondStyleDiff;
              }
              if (nbrNewLamp>=500) {
                return firstStyleDiff;
              }
          }
          
        //}

      }
    });
    myLayer.addTo(map);
    return myLayer;
  }
  },
};

