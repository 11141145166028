/*************************************************************************
*
*
* THIS FUNCTIONS ARE USED TO CALCULATE THE NUMBER OF NEW LAMP
* IN EACH AREA (zone Cizo, Village Cizo, Zone frontiere)
*
**************************************************************************/

//
//Function to calculate all the new lampadaires on a frontiere 
//
export const computeStreetlightNewNbrBorder = (data,frontiere,ratio)=>{
	var nbrLampadaireCible= 0;
	var nbrNewLampadairesAConstruire = 0;
	if(parseInt(ratio)>0){
		if(data.properties['frontiere'] === frontiere){
			let populationZoneFrontiere = parseInt(data.properties['popCouv']);
			let nbrLampadaireActuel= parseInt(data.properties['lampCouv']);
			nbrLampadaireCible= Math.round(populationZoneFrontiere / parseInt(ratio));
			nbrNewLampadairesAConstruire = nbrLampadaireCible - nbrLampadaireActuel;
		}
	}
	if (nbrNewLampadairesAConstruire<0){
		nbrNewLampadairesAConstruire = 0;
	}
	return nbrNewLampadairesAConstruire;
}



//
//Function to calculate all the new lampadaires in Villages CISO
//
export const computeStreetlightNewNbrCizoVillage = (data,ratio)=>{
	var nbrLampadaireCible= 0;
	var nbrNewLampadairesAConstruire = 0;
	if(parseInt(ratio)>0){
		let populationVillageCizo= parseInt(data.properties['popCouv']);
		let nbrLampadaireActuel= parseInt(data.properties['lampCouv']);
		nbrLampadaireCible=  Math.round(populationVillageCizo / parseInt(ratio));
		nbrNewLampadairesAConstruire = nbrLampadaireCible - nbrLampadaireActuel;
	}
	if (nbrNewLampadairesAConstruire<0){
		nbrNewLampadairesAConstruire = 0;
	}
	return nbrNewLampadairesAConstruire;
}



//
//Function to calculate all the new lampadaires in Zone CISO
//
export const computeStreetlightNewNbrCizoZone = (data,ratio)=>{
	var nbrLampadaireCible= 0;
	var nbrNewLampadairesAConstruire = 0;
	if(parseInt(ratio)>0){
			let populationZoneCizo = parseInt(data.properties['popCouv']);
			let nbrLampadaireActuel= parseInt(data.properties['lampCouv']);
			nbrLampadaireCible= Math.round(populationZoneCizo / parseInt(ratio));
			nbrNewLampadairesAConstruire = nbrLampadaireCible - nbrLampadaireActuel;
	}
	if (nbrNewLampadairesAConstruire<0){
		nbrNewLampadairesAConstruire = 0;
	}
	return nbrNewLampadairesAConstruire;
}



//
//Function to calculate all the total of needed lampadaires in areas except CIZO zones for Infrastructure
//
export const computeStreetlightNewNbrForInfrastructure = (data,typeInfra)=>{
	var nbrNewLampadaires = 0;
	const COEFFICIENT_ECOLE_SANTE= 2;
	const COEFFICIENT_EAU= 1;
	switch(typeInfra) {
	  case 'ecole':
	    nbrNewLampadaires = parseInt(data.properties['ecoleCouv']) * COEFFICIENT_ECOLE_SANTE;
	    break;
	  case 'sante':
	    nbrNewLampadaires = parseInt(data.properties['santeCouv']) * COEFFICIENT_ECOLE_SANTE;
	    break;
	  case 'eau':
	    nbrNewLampadaires = parseInt(data.properties['eauCouv']) * COEFFICIENT_EAU;
	    break;
	  default:
	    // code block
	}
	return nbrNewLampadaires;
}


