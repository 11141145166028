import React from 'react';
import './header.scss';
import { NavLink } from 'react-router-dom';
import sunnaLogo from "../../assets/images/sunna-white-logo.svg";
import { Button } from '@mui/material';
import { disconnectUser } from '../../store/reducers/userReducer';
import { useDispatch } from 'react-redux';
import { setMessage, setColor, setOpen, setXposition, setYposition } from '../../store/reducers/snackBarReducer';

const Header = () => {

    /* Logout */

    const dispatch = useDispatch();

    const disconnect = () => {
        localStorage.setItem('user', null);
        dispatch(disconnectUser());
        dispatch(setMessage({ message: "Vous êtes déconnecté" }));
        dispatch(setColor({ color: 'info' }));
        dispatch(setXposition({ xPosition: 'right' }))
        dispatch(setYposition({ yPosition: 'top' }))
        dispatch(setOpen({ open: true }))
    }

    const refreshPage = () => {
        window.location.reload();
    }

    return (
        <div className="header">
            <div className="header_left" onClick={refreshPage}>
                <img src={sunnaLogo} alt="logo" />
            </div>
            <div className="header_right">
                <div className="header_right_text" onClick={refreshPage}>
                    <h1>CIZO-EP</h1>
                    <div className="divider"></div>
                    <p>
                        Simulateur d’éclairage frontalier et rural
                    </p>
                </div>
                <div className="header_right_logout">
                    <NavLink to="/accueil">
                        Accueil
                    </NavLink>
                    <NavLink to="/a-propos">
                        A propos
                    </NavLink>
                    <Button onClick={disconnect}>Déconnexion</Button>
                </div>
            </div>
        </div>
    )
}

export default Header;