import React from "react";
import './footer.scss';

const Footer = () => {
    return (
        <div className="footer">
            <p>Développé par Mitsio Motu | Assistance téchnique : +228 70 50 58 61</p>
        </div>
    )
}

export default Footer;