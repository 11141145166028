import { createSlice } from '@reduxjs/toolkit';

const initialStateValue = {
    totalLampadaires: 0,
    totalFrontiere: 0,
    totalFrontiereSavanes: 0,
    totalFrontiereOuest: 0,
    totalFrontiereEst: 0,
    totalFrontiereSudEst: 0,
    totalFrontiereElectrifie: 0,
    totalVillageCiso: 0,
    totalVillageCisoSavanes: 0,
    totalVillageCisoAutresRegions: 0,
    totalZoneCiso: 0,
    totalZoneCisoSavanes: 0,
    totalZoneCisoAutresRegions: 0,
    totalInfraHorsCisoEducationSavanes: 0,
    totalInfraHorsCisoSanteSavanes: 0,
    totalInfraHorsCisoEauSavanes: 0,
    totalInfraHorsCisoEducationAutresRegions: 0,
    totalInfraHorsCisoSanteAutresRegions: 0,
    totalInfraHorsCisoEauAutresRegions: 0,
};

/* eslint-disable */
export const simulationResultSlice = createSlice({
    name: 'simulationResult',
    initialState: { value: initialStateValue },
    reducers: {
        updateSimulationResult: (state, action) => {
            state.value = action.payload;
        },
    }
});
/* eslint-enable */

export const { updateSimulationResult } = simulationResultSlice.actions;
export default simulationResultSlice.reducer;