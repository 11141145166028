import React from "react";
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useSelector } from "react-redux";

const ProtectedRoute = ({ children }) => {
    const location = useLocation()
    const user = useSelector(state => state.user.currentUser)
    if (!user){
        return <Navigate to={`/?redirect=${location.pathname}`} replace />;
    }else{
        if (user.username!=="boris@mitsiomotu.com"){
            return <Navigate to={`/?redirect=${location.pathname}`} replace />;
        }
    }
    return children ? children : <Outlet />;
};

export default ProtectedRoute