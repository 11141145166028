import React from 'react';
import './not-found.scss';
import { Link } from 'react-router-dom';

const NotFound = () => (
    <div className="not-found">
        <div className="not-found-inner">
            <p>Page indisponible</p>
            <Link to="/accueil">Revenir à l'accueil</Link>
        </div>
    </div>
)

export default NotFound;