const users = [
    {
        id: 1,
        email: "boris@mitsiomotu.com",
        password: "fmvhth2q"
    }/*,
    {
        id: 2,
        email: "louis@mitsiomotu.com",
        password: "nj8adto8"
    },
    {
        id: 3,
        email: "benoityandja@yahoo.fr",
        password: "227vcw7u"
    },
    {
        id: 4,
        email: "damien.hay@sunna-design.fr",
        password: "uynuqye9"
    },
    {
        id: 5,
        email: "thibaud.des-deserts@sunna-design.fr",
        password: "4dqgy252"
    },
    {
        id: 6,
        email: "thomas@sunna-design.fr",
        password: "kx5udqth"
    },
    {
        id: 7,
        email: "nassoma.robil@gmail.com",
        password: "vd5gcd93"
    },
    {
        id: 8,
        email: "amynabiliou@gmail.com",
        password: "x3tUYxDu"
    }*/
]

export default users;
