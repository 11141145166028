import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    open: false,
    message: "",
    color: "",
    xPosition: "",
    yPosition: ""
};

const snackBarSlice = createSlice({
    name: 'snackbar',
    initialState: initialState,
    reducers: {
        setOpen: (state, action) => {
            state.open = action.payload.open
        },
        setMessage: (state, action) => {
            state.message = action.payload.message
        },
        setColor: (state, action) => {
            state.color = action.payload.color
        },
        setXposition: (state, action) => {
            state.xPosition = action.payload.xPosition
        },
        setYposition: (state, action) => {
            state.yPosition = action.payload.yPosition
        },
    }

});

export const { setOpen, setMessage, setColor, setXposition, setYposition } = snackBarSlice.actions;
export default snackBarSlice.reducer;