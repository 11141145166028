import { createSlice } from '@reduxjs/toolkit';

const initialStateValue = {
    value: {
        status: false
    }
}

/* eslint-disable */
export const resultStateSlice = createSlice({
    name: 'resultStatus',
    initialState: { value: initialStateValue },
    reducers: {
        toggleStatus: (state, action) => {
            state.value.status = !state.value.status;
        }
    }
});
/* eslint-enable */

export const { toggleStatus } = resultStateSlice.actions;
export default resultStateSlice.reducer;