import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isAuthenticated: false,
    currentUser: null,
};

/* eslint-disable */
const userSlice = createSlice({
    name: 'user',
    initialState: initialState,
    reducers: {
        saveUser: (state, action) => {
            state.currentUser = action.payload.user
            state.isAuthenticated = true
        },
        disconnectUser: (state) => {
            state.currentUser = null
            state.isAuthenticated = false
        },
    }
});
/* eslint-enable */

export const { saveUser, disconnectUser } = userSlice.actions;
export default userSlice.reducer;