
export const baseLayers = [
  {
    name: "OSM",
    urlTemplate: "http://{s}.tile.osm.org/{z}/{x}/{y}.png",
    options: {
      attribution: "© OpenStreetMap contributors",
      maxZoom: 19,
    },
  },
  {
    name: "OSM-Black-and-White",
    urlTemplate:
      "http://{s}.www.toolserver.org/tiles/bw-mapnik/{z}/{x}/{y}.png",
    options: {
      attribution:
        // eslint-disable-next-line
        "&copy; " + "<a href='http://www.openstreetmap.org'>Open StreetMap</a>",
      maxZoom: 18,
    },
  },
  {
    name: "OSM-Humanitaire",
    urlTemplate: "http://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png",
    options: {
      attribution:
        // eslint-disable-next-line
        "© OpenStreetMap Contributors. Tiles courtesy of Humanitarian OpenStreetMap Team",
      maxZoom: 20,
    },
  },

  {
    name: "Positron",
    urlTemplate: "http://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png",
    options: {
      attribution: "&copy; <a href='http://www.openstreetmap.org/copyright'>OpenStreetMap</a> contributors, &copy; <a href='http://cartodb.com/attributions'>CartoDB</a>",
      maxZoom: 20,
    },
  },
];


export const countyPositions = [
  { id: 0, name: "Pays", latLng: [8.22, 1.13], zoom: 7 },
  { id: 1, name: "Avé", latLng: [6.48159, 0.910492], zoom: 12 },
  { id: 2, name: "Bas-Mono", latLng: [6.487289, 1.652756], zoom: 12 },
  { id: 3, name: "Lacs", latLng: [6.320075, 1.684113], zoom: 12 },
  { id: 4, name: "Vo", latLng: [6.384729, 1.494598], zoom: 12 },
  { id: 5, name: "Yoto", latLng: [6.676832, 1.453857], zoom: 13 },
  { id: 6, name: "Zio", latLng: [6.542583, 1.180573], zoom: 13 },
  { id: 7, name: "Cinkassé", latLng: [11.046472, 0.102253], zoom: 12 },
  { id: 8, name: "Tône", latLng: [10.898949, 0.220642], zoom: 12 },
  { id: 9, name: "Kpendjal Ouest", latLng: [10.813728, 0.434361], zoom: 12 },
  { id: 10, name: "Kpendjal", latLng: [10.797307, 0.693398], zoom: 11 },
  { id: 11, name: "Tadjoare", latLng: [10.658015, 0.146484], zoom: 11 },
  { id: 12, name: "Oti", latLng: [10.475515, 0.413704], zoom: 11 },
  { id: 13, name: "Oti Sud", latLng: [10.219631, 0.627651], zoom: 11 },
  { id: 14, name: "Savane", latLng: [10.684811, 0.44014], zoom: 10 },
  { id: 15, name: "Savane", latLng: [10.684811, 0.44014], zoom: 12 }
];

export const regionsPrefectureList = [
    {
        id: 1,
        label: 'Savanes',
        prefectures: [
            { id: 7, name: "Cinkassé", latLng: [11.046472, 0.102253], zoom: 13 },
            { id: 8, name: "Tône", latLng: [10.898949, 0.220642], zoom: 12 },
            { id: 9, name: "Kpendjal Ouest", latLng: [10.813728, 0.434361], zoom: 13 },
            { id: 10, name: "Kpendjal", latLng: [10.797307, 0.693398], zoom: 12 },
            { id: 11, name: "Tadjoare", latLng: [10.658015, 0.146484], zoom: 12 },
            { id: 12, name: "Oti", latLng: [10.475515, 0.413704], zoom: 12 },
            { id: 13, name: "Oti Sud", latLng: [10.219631, 0.627651], zoom: 13 }
        ]
    },
    {
        id: 2,
        label: 'Maritime',
        prefectures: [
            { id: 1, name: "Avé", latLng: [6.48159, 0.910492], zoom: 13 },
            { id: 2, name: "Bas-Mono", latLng: [6.487289, 1.652756], zoom: 13 },
            { id: 3, name: "Lacs", latLng: [6.320075, 1.684113], zoom: 13 },
            { id: 4, name: "Vo", latLng: [6.384729, 1.494598], zoom: 13 },
            { id: 5, name: "Yoto", latLng: [6.676832, 1.453857], zoom: 13 },
            { id: 6, name: "Zio", latLng: [6.542583, 1.180573], zoom: 13 },
        ]
    }
];




export const regionsList = [
  {
    regionId: 1,
    regionName: 'Maritime',
    sectors: [
      {
        sectorId: 1,
        sectorPercent: 100,
        sectorName: 'Secteur 1',
      },
      {
        sectorId: 2,
        sectorPercent: 21,
        sectorName: 'Secteur 2',
      },
      {
        sectorId: 3,
        sectorPercent: 80,
        sectorName: 'Secteur 3',
      },
      {
        sectorId: 4,
        sectorPercent: 11,
        sectorName: 'Secteur 4',
      },
      {
        sectorId: 5,
        sectorPercent: 0,
        sectorName: 'Secteur 5',
      },
      {
        sectorId: 6,
        sectorPercent: 0,
        sectorName: 'Secteur 6',
      }
    ]
  },
  {
    regionId: 2,
    regionName: 'Plateaux',
    sectors: [
      {
        sectorId: 1,
        sectorPercent: 42,
        sectorName: 'Secteur 1',
      },
      {
        sectorId: 2,
        sectorPercent: 15,
        sectorName: 'Secteur 2',
      },
      {
        sectorId: 3,
        sectorPercent: 22,
        sectorName: 'Secteur 3',
      },
      {
        sectorId: 4,
        sectorPercent: 0,
        sectorName: 'Secteur 4',
      },
      {
        sectorId: 5,
        sectorPercent: 0,
        sectorName: 'Secteur 5',
      },
      {
        sectorId: 6,
        sectorPercent: 0,
        sectorName: 'Secteur 6',
      }
    ]
  },
  {
    regionId: 3,
    regionName: 'Centrale',
    sectors: [
      {
        sectorId: 1,
        sectorPercent: 40,
        sectorName: 'Secteur 1',
      },
      {
        sectorId: 2,
        sectorPercent: 64,
        sectorName: 'Secteur 2',
      },
      {
        sectorId: 3,
        sectorPercent: 33,
        sectorName: 'Secteur 3',
      },
      {
        sectorId: 4,
        sectorPercent: 0,
        sectorName: 'Secteur 4',
      },
      {
        sectorId: 5,
        sectorPercent: 0,
        sectorName: 'Secteur 5',
      },
      {
        sectorId: 6,
        sectorPercent: 0,
        sectorName: 'Secteur 6',
      }
    ]
  },
  {
    regionId: 4,
    regionName: 'Kara',
    sectors: [
      {
        sectorId: 1,
        sectorPercent: 0,
        sectorName: 'Secteur 1',
      },
      {
        sectorId: 2,
        sectorPercent: 0,
        sectorName: 'Secteur 2',
      },
      {
        sectorId: 3,
        sectorPercent: 0,
        sectorName: 'Secteur 3',
      },
      {
        sectorId: 3,
        sectorPercent: 0,
        sectorName: 'Secteur 4',
      },
      {
        sectorId: 3,
        sectorPercent: 0,
        sectorName: 'Secteur 5',
      },
      {
        sectorId: 3,
        sectorPercent: 0,
        sectorName: 'Secteur 6',
      }
    ]
  },
  {
    regionId: 5,
    regionName: 'Savanes',
    sectors: [
      {
        sectorId: 1,
        sectorPercent: 0,
        sectorName: 'secteur 1',
      },
      {
        sectorId: 2,
        sectorPercent: 0,
        sectorName: 'secteur 2',
      },
      {
        sectorId: 3,
        sectorPercent: 0,
        sectorName: 'secteur 3',
      },
      {
        sectorId: 1,
        sectorPercent: 0,
        sectorName: 'secteur 4',
      },
      {
        sectorId: 5,
        sectorPercent: 0,
        sectorName: 'secteur 5',
      },
      {
        sectorId: 6,
        sectorPercent: 0,
        sectorName: 'Secteur 6',
      }
    ]
  },
  {
    regionId: 6,
    regionName: 'Grand Lomé',
    sectors: [
      {
        sectorId: 1,
        sectorPercent: 0,
        sectorName: 'secteur 1',
      },
      {
        sectorId: 2,
        sectorPercent: 0,
        sectorName: 'secteur 2',
      },
      {
        sectorId: 3,
        sectorPercent: 0,
        sectorName: 'secteur 3',
      },
      {
        sectorId: 1,
        sectorPercent: 0,
        sectorName: 'secteur 4',
      },
      {
        sectorId: 5,
        sectorPercent: 0,
        sectorName: 'secteur 5',
      },
      {
        sectorId: 6,
        sectorPercent: 0,
        sectorName: 'Secteur 6',
      }
    ]
  }
]

export const sectorsList = [
  {
    sectorId: 1,
    sectorName: 'Secteur 1',
    sectorStatus: 'En cours'
  },
  {
    sectorId: 2,
    sectorName: 'Secteur 2',
    sectorStatus: 'En cours'
  },
  {
    sectorId: 3,
    sectorName: 'Secteur 3',
    sectorStatus: 'En cours'
  },
  {
    sectorId: 3,
    sectorName: 'Secteur 4',
    sectorStatus: 'En cours'
  },
  {
    sectorId: 3,
    sectorName: 'Secteur 5',
    sectorStatus: 'En cours'
  },
  {
    sectorId: 3,
    sectorName: 'Secteur 6',
    sectorStatus: 'En cours'
  }
]

export const accessibility = [
  {
    month: 'Octobre',
    status: 'En cours'
  },
  {
    month: 'novembre',
    status: 'Terminé'
  },
  {
    month: 'Décembre',
    status: 'En cours'
  },
  {
    month: 'Janvier',
    status: 'En cours'
  },
  {
    month: 'Février',
    status: 'En cours'
  },
  {
    month: 'Mars',
    status: 'En cours'
  }
]
