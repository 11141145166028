//import { useSelector } from 'react-redux';
import { produce } from "immer";

import dataDiffentBorderDistanceScenario from "../data_calcul/togo_export_site_concat_with_front_electrifie.json";
//import dataKm from '../data_calcul/togo_export_site_concat_new.json';
//import dataCalculatedKm from '../data/togo_eclairage_base_calcul.json'
import dataCalculatedCantonsKm from "../data/togo_eclairage_calculated_cantons.json";
import {
  computeStreetlightNewNbrBorder,
  computeStreetlightNewNbrCizoVillage,
  computeStreetlightNewNbrCizoZone,
  computeStreetlightNewNbrForInfrastructure
} from "./simulatorServiceUtilities";

/**********************************************************************************************
 *
 * Function to calculate all the total of needed lampadaires
 * considering the selected frontiere Rayons and the ratio
 *
 *
 * ********************************************************************************************/
export const simulatorService = (sentSimulationData, frontiereKmSelected) => {
  const data = {
    totalLampadaires: 0,
    totalFrontiere: 0,
    totalFrontiereSavanes: 0,
    totalFrontiereOuest: 0,
    totalFrontiereEst: 0,
    totalFrontiereSudEst: 0,
    totalFrontiereElectrifie: 0,
    totalVillageCiso: 0,
    totalVillageCisoSavanes: 0,
    totalVillageCisoAutresRegions: 0,
    totalZoneCiso: 0,
    totalZoneCisoSavanes: 0,
    totalZoneCisoAutresRegions: 0,
    totalInfraHorsCisoEducationSavanes: 0,
    totalInfraHorsCisoSanteSavanes: 0,
    totalInfraHorsCisoEauSavanes: 0,
    totalInfraHorsCisoEducationAutresRegions: 0,
    totalInfraHorsCisoSanteAutresRegions: 0,
    totalInfraHorsCisoEauAutresRegions: 0,
  };
  const simulationRequestData = sentSimulationData;

  var totalLampadaires = 0;
  var totalFrontiere = 0;
  var totalFrontiereSavanes = 0;
  var totalFrontiereOuest = 0;
  var totalFrontiereEst = 0;
  var totalFrontiereSudEst = 0;
  var totalFrontiereElectrifie = 0;
  var totalVillageCiso = 0;
  var totalVillageCisoSavanes = 0;
  var totalVillageCisoAutresRegions = 0;
  var totalZoneCiso = 0;
  var totalZoneCisoSavanes = 0;
  var totalZoneCisoAutresRegions = 0;
  var totalInfraHorsCisoEducationSavanes = 0;
  var totalInfraHorsCisoSanteSavanes = 0;
  var totalInfraHorsCisoEauSavanes = 0;
  var totalInfraHorsCisoEducationAutresRegions = 0;
  var totalInfraHorsCisoSanteAutresRegions = 0;
  var totalInfraHorsCisoEauAutresRegions = 0;

  dataDiffentBorderDistanceScenario.features.forEach(function (feature) {
    if (feature.properties["km"] === frontiereKmSelected * 1000) {
      if (feature.properties["type"] === "Frontière") {
        totalFrontiereSavanes += computeStreetlightNewNbrBorder(feature,"Savanes",simulationRequestData.frontSavanes);
        totalFrontiereOuest +=   computeStreetlightNewNbrBorder(feature,"Ouest",simulationRequestData.frontOuest);
        totalFrontiereEst +=   computeStreetlightNewNbrBorder(feature,"Est",simulationRequestData.frontEst);
        totalFrontiereSudEst +=   computeStreetlightNewNbrBorder(feature,"Sud-Est",simulationRequestData.frontSudEst);
        totalFrontiereElectrifie +=   computeStreetlightNewNbrBorder(feature,"Electrifie",simulationRequestData.frontElectrifie);
      }
      if (feature.properties["type"] === "Villages CIZO") {
        if (feature.properties["region_nom"] === "Savanes") {
          totalVillageCisoSavanes += computeStreetlightNewNbrCizoVillage(feature,simulationRequestData.savanesVillageCizo);
        } else {
          totalVillageCisoAutresRegions += computeStreetlightNewNbrCizoVillage(feature,simulationRequestData.autresRegionsVillageCizo);
        }
      }
      if (feature.properties["type"] === "Zone CIZO") {
        if (feature.properties["region_nom"] === "Savanes") {
          totalZoneCisoSavanes += computeStreetlightNewNbrCizoZone(feature,simulationRequestData.savanesZoneCizo);
        } else {
          totalZoneCisoAutresRegions += computeStreetlightNewNbrCizoZone(feature,simulationRequestData.autresRegionsZoneCizo);
        }
      }
      if (feature.properties["type"] === "Reste") {
        if (feature.properties["region_nom"] === "Savanes") {
          if (simulationRequestData.checkboxEducationSavanes === true) {
            totalInfraHorsCisoEducationSavanes += computeStreetlightNewNbrForInfrastructure(feature,"ecole");
          }
          if (simulationRequestData.checkboxHealthSavanes === true) {
            totalInfraHorsCisoSanteSavanes += computeStreetlightNewNbrForInfrastructure(feature,"sante");
          }
          if (simulationRequestData.checkboxWaterSavanes === true) {
            totalInfraHorsCisoEauSavanes += computeStreetlightNewNbrForInfrastructure(feature,"eau");
          }
        } else {
          if (simulationRequestData.checkboxEducationAutresRegions === true) {
            totalInfraHorsCisoEducationAutresRegions += computeStreetlightNewNbrForInfrastructure(feature,"ecole");
          }
          if (simulationRequestData.checkboxHealthAutresRegions === true) {
            totalInfraHorsCisoSanteAutresRegions += computeStreetlightNewNbrForInfrastructure(feature,"sante");
          }
          if (simulationRequestData.checkboxWaterAutresRegions === true) {
            totalInfraHorsCisoEauAutresRegions += computeStreetlightNewNbrForInfrastructure(feature,"eau");
          }
        }
      }
    }
  });

  data.totalFrontiereSavanes = totalFrontiereSavanes;
  data.totalFrontiereOuest = totalFrontiereOuest;
  data.totalFrontiereEst = totalFrontiereEst;
  data.totalFrontiereSudEst = totalFrontiereSudEst;
  data.totalFrontiereElectrifie = totalFrontiereElectrifie;
  totalFrontiere =
    totalFrontiereSavanes +
    totalFrontiereOuest +
    totalFrontiereEst +
    totalFrontiereSudEst +
    totalFrontiereElectrifie;
  data.totalFrontiere = totalFrontiere;

  data.totalVillageCisoSavanes = totalVillageCisoSavanes;
  data.totalVillageCisoAutresRegions = totalVillageCisoAutresRegions;
  totalVillageCiso = totalVillageCisoSavanes + totalVillageCisoAutresRegions;
  data.totalVillageCiso = totalVillageCiso;

  data.totalZoneCisoSavanes = totalZoneCisoSavanes;
  data.totalZoneCisoAutresRegions = totalZoneCisoAutresRegions;
  totalZoneCiso = totalZoneCisoSavanes + totalZoneCisoAutresRegions;
  data.totalZoneCiso = totalZoneCiso;

  data.totalInfraHorsCisoEducationSavanes = totalInfraHorsCisoEducationSavanes;
  data.totalInfraHorsCisoSanteSavanes = totalInfraHorsCisoSanteSavanes;
  data.totalInfraHorsCisoEauSavanes = totalInfraHorsCisoEauSavanes;
  data.totalInfraHorsCisoEducationAutresRegions =
    totalInfraHorsCisoEducationAutresRegions;
  data.totalInfraHorsCisoSanteAutresRegions =
    totalInfraHorsCisoSanteAutresRegions;
  data.totalInfraHorsCisoEauAutresRegions = totalInfraHorsCisoEauAutresRegions;

  let totalInfraSavanes =
    totalInfraHorsCisoEducationSavanes +
    totalInfraHorsCisoSanteSavanes +
    totalInfraHorsCisoEauSavanes;
  let totalInfraAutresRegions =
    totalInfraHorsCisoEducationAutresRegions +
    totalInfraHorsCisoSanteAutresRegions +
    totalInfraHorsCisoEauAutresRegions;
  totalLampadaires =
    totalFrontiere +
    totalVillageCiso +
    totalZoneCiso +
    totalInfraSavanes +
    totalInfraAutresRegions;
  data.totalLampadaires = totalLampadaires;

  return data;
};

/********************************************************************
 * Here we do the calculation of the number of new and targeted
 * Lamp in each canton.
 *
 *
 *
 *********************************************************************/
export const cantonTotalLampadaire = (sentSimulationData, borderSelectedKm) => {
  const cantonsInputData = dataCalculatedCantonsKm;
  let modifiedTable = { ...dataCalculatedCantonsKm };
  let modifiedTableFinal = [];
  var cpt = 0;

  cantonsInputData.features.forEach(function (cantonInputData) {
    let populationInCanton = parseInt(cantonInputData.properties["popCouv"]);
    //let currentRatio = parseInt(cantonInputData.properties["hab_lamp"]);
    let streetlightCurrentNbrCounty = parseInt(
      cantonInputData.properties["lampCouv"]
    );

    var streelightFinalNbrCounty = 0;
    var streetlightToBeCreatedNbrCounty = 0;
    var streetlightPercentageIncrease = 0;
    var newComputedRatio = 0;

    const foundCantonOccurences = dataDiffentBorderDistanceScenario.features.filter(
      (element) =>
        parseInt(element.properties["km"]) === parseInt(borderSelectedKm) * 1000 
        && element.properties["canton_id"] === cantonInputData.properties["canton_id"]
    );

    const computeStreetlightTotalTargetNbr = (foundCantonOccurences) => {
      foundCantonOccurences.forEach(function (cantonOccurence) {
        if (cantonOccurence.properties["type"] === "Frontière") {
          streetlightToBeCreatedNbrCounty += computeStreetlightNewNbrBorder(cantonOccurence,"Savanes",sentSimulationData.frontSavanes);
          streetlightToBeCreatedNbrCounty += computeStreetlightNewNbrBorder(cantonOccurence,"Ouest",sentSimulationData.frontOuest);
          streetlightToBeCreatedNbrCounty += computeStreetlightNewNbrBorder(cantonOccurence,"Est",sentSimulationData.frontEst);
          streetlightToBeCreatedNbrCounty += computeStreetlightNewNbrBorder(cantonOccurence,"Sud-Est",sentSimulationData.frontSudEst);
          streetlightToBeCreatedNbrCounty += computeStreetlightNewNbrBorder(cantonOccurence,"Electrifie",sentSimulationData.frontElectrifie);
        }
        if (cantonOccurence.properties["type"] === "Villages CIZO") {
          if (cantonInputData.properties["region_nom"] === "Savanes") {
            streetlightToBeCreatedNbrCounty += computeStreetlightNewNbrCizoVillage(cantonOccurence,sentSimulationData.savanesVillageCizo);
          } else {
            streetlightToBeCreatedNbrCounty += computeStreetlightNewNbrCizoVillage(cantonOccurence,sentSimulationData.autresRegionsVillageCizo);
          }
        }
        if (cantonOccurence.properties["type"] === "Zone CIZO") {
          if (cantonInputData.properties["region_nom"] === "Savanes") {
            streetlightToBeCreatedNbrCounty += computeStreetlightNewNbrCizoZone(cantonOccurence,sentSimulationData.savanesZoneCizo);
          } else {
            streetlightToBeCreatedNbrCounty += computeStreetlightNewNbrCizoZone(cantonOccurence,sentSimulationData.autresRegionsZoneCizo);
          }
        }
        if (cantonOccurence.properties["type"] === "Reste") {
          if (cantonOccurence.properties["region_nom"] === "Savanes") {
            if (sentSimulationData.checkboxEducationSavanes === true) {
              streetlightToBeCreatedNbrCounty +=computeStreetlightNewNbrForInfrastructure(cantonOccurence,"ecole");
            }
            if (sentSimulationData.checkboxHealthSavanes === true) {
              streetlightToBeCreatedNbrCounty += computeStreetlightNewNbrForInfrastructure(cantonOccurence,"sante");
            }
            if (sentSimulationData.checkboxWaterSavanes === true) {
              streetlightToBeCreatedNbrCounty +=computeStreetlightNewNbrForInfrastructure(cantonOccurence, "eau");
            }
          } else {
            if (sentSimulationData.checkboxEducationAutresRegions === true) {
              streetlightToBeCreatedNbrCounty += computeStreetlightNewNbrForInfrastructure(cantonOccurence,"ecole");
            }
            if (sentSimulationData.checkboxHealthAutresRegions === true) {
              streetlightToBeCreatedNbrCounty += computeStreetlightNewNbrForInfrastructure(cantonOccurence,"sante");
            }
            if (sentSimulationData.checkboxWaterAutresRegions === true) {
              streetlightToBeCreatedNbrCounty += computeStreetlightNewNbrForInfrastructure(cantonOccurence,"eau");
            }
          }
        }
      });
    };

    computeStreetlightTotalTargetNbr(foundCantonOccurences);
    streelightFinalNbrCounty = streetlightToBeCreatedNbrCounty + streetlightCurrentNbrCounty;
    if (streelightFinalNbrCounty > 0) {
      newComputedRatio = Math.round(populationInCanton / streelightFinalNbrCounty);
      
    }

    if (streetlightCurrentNbrCounty > 0) {
      streetlightPercentageIncrease = (streetlightToBeCreatedNbrCounty / streetlightCurrentNbrCounty) * 100;
    }

    //**** here we add the properties of our temporaryObj to the modifiedTable ***//
    const modifiedObject = produce(
      modifiedTable.features[cpt],
      (tempObject) => {
        tempObject.properties.lamp_construire = streelightFinalNbrCounty;
        tempObject.properties.lamp_nov = streetlightToBeCreatedNbrCounty;
        tempObject.properties.ratio_nouv = newComputedRatio;
        tempObject.properties.ratio_pris_en_compte = newComputedRatio;
        tempObject.properties.lamp_diff = streetlightPercentageIncrease;
      }
    );
    modifiedTableFinal.push(modifiedObject);
    cpt++;
  });

  return modifiedTableFinal;
};
