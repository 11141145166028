import React, { useEffect } from 'react';
import './login.scss';
import { useSelector } from 'react-redux';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { CircularProgress } from '@mui/material';
import sunnaLogo from "../../assets/images/sunna-design-logo.svg";
import gouvernement from "../../assets/images/togo-logo.svg";
//import MMLogo from "../../assets/images/mm-logo.svg";
import Button from '@mui/material/Button';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setMessage, setColor, setOpen, setXposition, setYposition } from "../../store/reducers/snackBarReducer";
import { saveUser } from '../../store/reducers/userReducer';
import users from "../../constants/users";

const Login = () => {

    const [values, setValues] = React.useState({
        amount: '',
        password: '',
        weight: '',
        weightRange: '',
        showPassword: false,
    });

    const navigate = useNavigate();

    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    let isAuthenticated = useSelector(state => state.user.isAuthenticated)

    useEffect(() => {
        if (isAuthenticated) {
            navigate('/accueil')
        }
        // eslint-disable-next-line
    }, [isAuthenticated])

    const dispatch = useDispatch();

    const findUser = (email, password) => {
        return (
            users.find((user) => (user.email === email && user.password === password))
        )
    }

    const formik = useFormik({
        initialValues: {
            username: '',
            password: '',
        },
        validationSchema: Yup.object({
            username: Yup.string()
                .required("Ce champ est requis"),
            password: Yup.string()
                .required('Ce champ est requis'),
        }),
        onSubmit: (values, actions) => {
            let userInfos = { ...values };
            if (findUser(userInfos.username, userInfos.password)) {
                dispatch(saveUser({ user: userInfos }));
                dispatch(setMessage({ message: "Vous êtes connecté" }));
                dispatch(setColor({ color: 'success' }));
                dispatch(setXposition({ xPosition: 'right' }))
                dispatch(setYposition({ yPosition: 'top' }))
                dispatch(setOpen({ open: true }))
                localStorage.setItem('user', JSON.stringify(userInfos));
                navigate('/accueil');
                formik.resetForm();
                actions.setSubmitting(false);
            } else {
                dispatch(setMessage({ message: "Courriel ou mot de passe incorrect" }));
                dispatch(setColor({ color: 'error' }));
                dispatch(setXposition({ xPosition: 'right' }));
                dispatch(setYposition({ yPosition: 'top' }));
                dispatch(setOpen({ open: true }));
                actions.setSubmitting(false);
            }
        },
    });

    return (
        <div className='login'>
            <div className="login-left">
                <div className="login-left-logo">
                    <div className="login-left-logo-item">
                        <img src={gouvernement} alt="logo" />
                    </div>
                    <div className="login-left-logo-item">
                        <img src={sunnaLogo} alt="logo" />
                    </div>
                </div>
                <div className="login-left-cizo-text">
                    <h1>CIZO-EP</h1>
                </div>
                <div className="login-left-form">
                    <h2>Accès client</h2>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="form-group">
                            <TextField
                                name="username"
                                label="Identifiant"
                                id="outlined-size-small"
                                size="small"
                                className={formik.errors.username && formik.touched.username ? ' is-invalid' : ''}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.username}
                            />
                            {formik.touched.username && formik.errors.username ? (
                                <p className="error">{formik.errors.username}</p>
                            ) : null}
                        </div>
                        <div className="form-group">
                            <FormControl
                                variant="outlined"
                                className={"mdp" + (formik.errors.password && formik.touched.password ? ' is-invalid' : '')}>
                                <InputLabel htmlFor="outlined-adornment-password">Mot de passe</InputLabel>
                                <OutlinedInput
                                    name="password"
                                    id="outlined-adornment-password"
                                    type={values.showPassword ? 'text' : 'password'}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.password}
                                    size="small"
                                    onChange={formik.handleChange}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Password"
                                />
                            </FormControl>
                            {formik.touched.password && formik.errors.password ? (
                                <p className="error">{formik.errors.password}</p>
                            ) : null}
                        </div>
                        <div className="form-group submit">
                            <Button type="submit" disabled={formik.isSubmitting} variant="contained">
                                <span>Se connecter</span>
                                {
                                    formik.isSubmitting ?
                                        (<CircularProgress />) : ('')
                                }
                            </Button>
                        </div>
                    </form>
                </div>
                <div className="login-left-footer">
                    <p>
                        Assistance téchnique : +228 70 50 58 61
                    </p>
                </div>
            </div>
            <div className="login-right">

            </div>
        </div>
    )
}

export default Login;
