import {
  Routes,
  Route
} from 'react-router-dom';
import './App.css';
/**/ 
import ProtectedRoute from './security/protected-route';
import Login from './pages/login/login';
import Home from './pages/home/home';
import About from './pages/about/about';
import NotFound from './pages/not-found/not-found';


function App() {
  
  return (
    <div className="App">
      <Routes>
    
        <Route
          exact
          path="/" element={<Login />} />
        <Route path="/accueil"
          element={
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/a-propos"
          element={
            <ProtectedRoute>
              <About />
            </ProtectedRoute>
          }
        />
    
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
