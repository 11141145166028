import React, { useState, useEffect, useRef } from 'react';
import './home.scss';
import { Button } from '@mui/material';
import { LightModeOutlined } from '@mui/icons-material';
import { StackedLineChart } from '@mui/icons-material';
import { CompareArrows } from '@mui/icons-material';
//import Radio from '@mui/material/Radio';
//import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
//import FormControl from '@mui/material/FormControl';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { AutoFixHigh } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Slider from '@mui/material/Slider';
import { useFormik } from 'formik';
import { CircularProgress } from '@mui/material';
//import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
//import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
//import MapWrapper from "../../components/map-wrapper/MapWrapper";
import SimulationResultComponent from "../../components/simulation-result/SimulationResultComponent";
import { simulatorService, cantonTotalLampadaire } from "../../services/simulatorService";
import { useDispatch, useSelector } from 'react-redux';
import { updateSimulationResult } from '../../store/reducers/simulationResultReducer';
import { addLayer } from '../../store/reducers/layerReducer';
import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';
import { FileDownload } from '@mui/icons-material';
//Imports for the old map functions
import Map from "../../components/map/Map";
import { baseLayers, countyPositions } from "../../assets/constants/const";
import LoadDataTask from "../../services/LoadDataTask";

import { useReactToPrint } from 'react-to-print';

import { setMessage, setColor, setOpen, setXposition, setYposition } from "../../store/reducers/snackBarReducer";
import {
    dataLoadInfos,
    FrontiereLayers,
    FrontiereSimulatedLayers

} from "./layers";

import villageCizoOnBorderNbr from '../../data_calcul/number_ciso_villages_distance_to_border.json'
/* import { set } from 'immer/dist/internal'; */

const Home = () => {

    /* Lines added to show map 
      These lines will not be used in our main project
    */
    const dispatch = useDispatch();
    const componentRef = useRef();
    const [subdivisionLevel, setSubdivisionLevel] = useState("canton");
    const [selectedRegion, setSelectedRegion] = useState(0);
    const [selectedPrefecture, setSelectedPrefecture] = useState(0);
    const [selectedCommune, setSelectedCommune] = useState(0);
    const [mapData, setMapData] = useState({});
    const [selectedCounty] = useState(0);

    /* End **/
    const [kmDiametre, setKmDiametre] = useState("frontiereKM0Info");
    const [simulationData, setSimulationData] = useState({});
    const [simulatedData, setSimulatedData] = useState([]);
    const [simulatedInfo, setSimulatedInfo] = useState("");
    const [ villageCizoSavanesNbr, setVillageCizoSavanesNbr ] = useState(villageCizoOnBorderNbr[0].villages_cizo_savanes);
    const [ villageCizoOtherNbr, setVillageCizoOtherNbr ] = useState(villageCizoOnBorderNbr[0].villages_cizo_other_regions);
    const [ totalVillageCizoNbr, setTotalVillageCizoNbr ] = useState(villageCizoOnBorderNbr[0].villages_cizo_savanes + villageCizoOnBorderNbr[0].villages_cizo_other_regions);
    const layers = [
        FrontiereLayers,
        FrontiereSimulatedLayers
    ];

    const layerSelectionButtons = {
        kmDiametre,
        setKmDiametre,
        simulatedData,
        setSimulatedData,
        simulatedInfo,
        setSimulatedInfo
    };

    const displayedLayers = {
        [FrontiereLayers.slug]: kmDiametre,
        [FrontiereSimulatedLayers.slug]: simulatedInfo
    };


    const loadData = function () {

        let mounted = { val: true };
        const loadDataTask = new LoadDataTask();
        dataLoadInfos.forEach((info) => {
            loadDataTask.load(info, mounted, setMapData);
        });
        console.log(mapData)
        return () => (mounted.val = false);
    };


    useEffect(() => {

        loadData();
        // eslint-disable-next-line
    }, []);

    const formik = useFormik({
        initialValues: {
            lampDistribute: 50000,
            frontSavanes: 0,
            frontOuest: 0,
            frontEst: 0,
            frontSudEst: 0,
            frontElectrifie: 0,
            rayonFrontalier: 0,
            savanesVillageCizo: 0,
            autresRegionsVillageCizo: 0,
            savanesZoneCizo: 0,
            autresRegionsZoneCizo: 0,
            checkboxEducationSavanes: true,
            checkboxHealthSavanes: true,
            checkboxWaterSavanes: true,
            checkboxEducationAutresRegions: true,
            checkboxHealthAutresRegions: true,
            checkboxWaterAutresRegions: true,
        },
        onSubmit: (values, actions) => {
            if (formik.values.rayonFrontalier > 0) {
                let parameters = { ...values };
                setSimulationData(parameters);
                let mydata = simulatorService(parameters, formik.values.rayonFrontalier);
                dispatch(updateSimulationResult(mydata));
                let cantonCalcul = cantonTotalLampadaire(parameters, formik.values.rayonFrontalier);
                dispatch(addLayer(cantonCalcul));
                setSimulatedData(cantonCalcul);
                console.log(parameters);
                setDisableSimulationButton(false);
                formik.setSubmitting(false);
            } else {
                dispatch(setMessage({ message: "Sélecitionnez d'abord le rayon frontalier désiré" }));
                dispatch(setColor({ color: 'warning' }));
                dispatch(setXposition({ xPosition: 'right' }))
                dispatch(setYposition({ yPosition: 'top' }))
                dispatch(setOpen({ open: true }))
            }


        },
    })



    /* Slider value change */

    const [SliderValue, setSliderValue] = React.useState(0);

    useEffect(() => {
        if (SliderValue === 0) setDisableSimulationButton(true)
        // eslint-disable-next-line
    }, [SliderValue]);

    const frontiereKMChangeHandler = (event, newValue) => {
        setSimulatedInfo("");
        setSliderValue(newValue);
        formik.values.rayonFrontalier = newValue;
        let selectedFrontiereDiametre = 'frontiereKM' + newValue + 'Info';
        setKmDiametre(selectedFrontiereDiametre);
        let villageCizoNumber = villageCizoOnBorderNbr.filter(
            (element) => parseInt(element.border_distance_km) === newValue  )[0];
        //console.log(villageCizoNumber);
        setVillageCizoSavanesNbr(villageCizoNumber.villages_cizo_savanes);
        setVillageCizoOtherNbr(villageCizoNumber.villages_cizo_other_regions);
        
    };

    useEffect(() => {
        setTotalVillageCizoNbr(villageCizoSavanesNbr + villageCizoOtherNbr);
        // eslint-disable-next-line
    }, [villageCizoSavanesNbr,villageCizoOtherNbr]);

    const handleExistantClick = () => {
        setSimulatedInfo("");
        let selectedKM = 0;// SliderValue;
        let selectedFrontiereDiametre = 'frontiereKM' + selectedKM + 'Info';
        setKmDiametre(selectedFrontiereDiametre);
    };

    const handleSimulationClick = () => {
        setSimulatedInfo("nouveau");
    };


    const handleDifferenceClick = () => {
        setSimulatedInfo("difference");
    };



    /* Parameters hide and show */
    const [isActiveParameters, setActiveParameters] = useState(false);

    const handleToggleParameters = () => {
        setActiveParameters(!isActiveParameters);
    };

    /* Get screen dimensions */
    const [screenSize, getDimension] = useState({
        dynamicWidth: window.innerWidth,
        dynamicHeight: window.innerHeight
    });

    const setDimension = () => {
        getDimension({
            dynamicWidth: window.innerWidth,
            dynamicHeight: window.innerHeight
        })
    };

    useEffect(() => {
        window.addEventListener('resize', setDimension);
        return (() => {
            window.removeEventListener('resize', setDimension);
            //if (screenSize.dynamicWidth <= 1200) setActiveParameters(true);
        })
    }, [screenSize]);


    /* Check simulator results window status */
    let resultStatusRetreive = useSelector(state => state.resultStatus.value.status)

    /* Selected Button */
    const [selectedButton, setSelectedButton] = useState('');
    const [disableSimulationButton, setDisableSimulationButton] = useState(true);

    const selectButton = (buttonSelected) => {
        console.log(buttonSelected);
        setSelectedButton(buttonSelected);
    }

    const printCurrentPage = useReactToPrint({
        content: () => componentRef.current,
    });

    useEffect(() => {

        loadData();
        // eslint-disable-next-line
    }, []);


    return (
        <div>
            <Header />
            <div className="home">
                <div className="home-inner">
                    <div className={"home-inner_body " + (isActiveParameters ? "closeParameters" : "openParameters") + ((resultStatusRetreive === true) ? " closeResults" : " openResults")}>
                        {/* Simulation parameters start here */}
                        <div className={"home-inner_body_parameters " + (isActiveParameters ? "close" : "open")}>
                            <form onSubmit={formik.handleSubmit}>
                                <div className="home-inner_body_parameters_content">
                                    <div className="home-inner_body_parameters_content_item">
                                        <div className="home-inner_body_parameters_content_left">
                                            <h2>Paramètres</h2>
                                        </div>
                                        <div className="home-inner_body_parameters_content_right">
                                            <p>
                                                <input
                                                    type="text"
                                                    name="lampDistribute"
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.lampDistribute}
                                                    onChange={formik.handleChange}
                                                />
                                                <span>Lampadaires à distribuer</span>
                                            </p>
                                        </div>
                                    </div>
                                    {/*<div className="home-inner_body_parameters_content_item radio-group">
                                        <div className="home-inner_body_parameters_content_item_left">
                                            <p>Calculer par :</p>
                                        </div>
                                        <div className="home-inner_body_parameters_content_item_right">
                                            <FormControl>
                                                <RadioGroup
                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                    defaultValue="female"
                                                    name="radio-buttons-group"
                                                >
                                                    <div className="radio-group-wrapper">
                                                        <FormControlLabel value="female" control={<Radio />} label="Ratio" />
                                                        <Tooltip title="Par exemple, une distribution de 20 habitants par lampadaire dans la région des Savanes." placement="top" arrow>
                                                            <InfoOutlinedIcon className="info" />
                                                        </Tooltip>
                                                    </div>
                                                    
                                                    <div className="radio-group-wrapper">
                                                        <FormControlLabel value="male" control={<Radio />} label="Pourcentage" />
                                                        <Tooltip title="info" placement="top" arrow>
                                                            <InfoOutlinedIcon className="info" />
                                                        </Tooltip>
                                                    </div>
                                                
                                                </RadioGroup>
                                            </FormControl>
                                        </div>
                                    </div>*/}
                                    <div className="home-inner_body_parameters_content_item title">
                                        <div className="home-inner_body_parameters_content_item_left">
                                            <h3>Frontières et ratio souhaité</h3>
                                        </div>
                                        <div className="home-inner_body_parameters_content_item_right">
                                            <Tooltip title="Entrez un ratio souhaité pour les quatre zones frontalières. Un ratio spécifique doit également etre alloué dans la partie electrifiée des zones frontalieres. Le District Autonome de Grand Lomé n'est pas inclus." placement="top" arrow>
                                                <InfoOutlinedIcon className="info" />
                                            </Tooltip>
                                        </div>
                                    </div>
                                    <div className="home-inner_body_parameters_content_item">
                                        <div className="home-inner_body_parameters_content_item_left">
                                            {/* <FormControlLabel
                                                control={
                                                    <Checkbox defaultChecked name="Frontière-Savanes" />
                                                }
                                                label="Frontière Savanes"
                                            />
                                            <Tooltip title="info" placement="top" arrow>
                                                <InfoOutlinedIcon className="info" />
                                            </Tooltip> */}
                                            <p>Frontière Savanes</p>
                                            <Tooltip title="La frontière des Savanes englobe toutes les frontières que la région des Savanes partage avec le Ghana, le Bénin et le Burkina Faso. " placement="top" arrow>
                                                <InfoOutlinedIcon className="info" />
                                            </Tooltip>
                                        </div>
                                        <div className="home-inner_body_parameters_content_item_right">
                                            <p>
                                                <input
                                                    type="text"
                                                    name="frontSavanes"
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.frontSavanes}
                                                    onChange={formik.handleChange}
                                                />
                                                <span>hab./lamp.</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="home-inner_body_parameters_content_item">
                                        <div className="home-inner_body_parameters_content_item_left">
                                            {/*  <FormControlLabel
                                                control={
                                                    <Checkbox defaultChecked name="Frontière-Ouest" />
                                                }
                                                label="Frontière Ouest"
                                            />
                                            <Tooltip title="info" placement="top" arrow>
                                                <InfoOutlinedIcon className="info" />
                                            </Tooltip> */}
                                            <p>Frontière Ouest</p>
                                            <Tooltip title="La frontière Ouest englobe les frontières de Centrale, Plateaux, Kara et Maritime partagées avec le Ghana." placement="top" arrow>
                                                <InfoOutlinedIcon className="info" />
                                            </Tooltip>
                                        </div>
                                        <div className="home-inner_body_parameters_content_item_right">
                                            <p>
                                                <input
                                                    type="text"
                                                    name="frontOuest"
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.frontOuest}
                                                    onChange={formik.handleChange}
                                                />
                                                <span>hab./lamp.</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="home-inner_body_parameters_content_item">
                                        <div className="home-inner_body_parameters_content_item_left">
                                            {/*  <FormControlLabel
                                                control={
                                                    <Checkbox defaultChecked name="Frontière-Est" />
                                                }
                                                label="Frontière Est"
                                            />
                                            <Tooltip title="info" placement="top" arrow>
                                                <InfoOutlinedIcon className="info" />
                                            </Tooltip> */}
                                            <p>Frontière Est</p>
                                            <Tooltip title="La frontière Est englobe les frontières de la région Centrale et de la Kara, communes avec le Bénin." placement="top" arrow>
                                                <InfoOutlinedIcon className="info" />
                                            </Tooltip>
                                        </div>
                                        <div className="home-inner_body_parameters_content_item_right">
                                            <p>
                                                <input
                                                    type="text"
                                                    name="frontEst"
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.frontEst}
                                                    onChange={formik.handleChange}
                                                />
                                                <span>hab./lamp.</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="home-inner_body_parameters_content_item">
                                        <div className="home-inner_body_parameters_content_item_left">
                                            <p>Frontière Sud-Est</p>
                                            <Tooltip title="La frontière Sud-Est englobe les frontières de la région des Plateaux et Maritime communes avec le Bénin, en dehors de la facade atlantique" placement="top" arrow>
                                                <InfoOutlinedIcon className="info" />
                                            </Tooltip>
                                        </div>
                                        <div className="hhome-inner_body_parameters_content_item_right">
                                            <p>
                                                <input
                                                    type="text"
                                                    name="frontSudEst"
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.frontSudEst}
                                                    onChange={formik.handleChange}
                                                />
                                                <span>hab./lamp.</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="home-inner_body_parameters_content_item">
                                        <div className="home-inner_body_parameters_content_item_left">
                                            <p>Frontière Electrifiée</p>
                                            <Tooltip title="La frontière Sud-Est englobe les frontières de la région des Plateaux et Maritime communes avec le Bénin." placement="top" arrow>
                                                <InfoOutlinedIcon className="info" />
                                            </Tooltip>
                                        </div>
                                        <div className="hhome-inner_body_parameters_content_item_right">
                                            <p>
                                                <input
                                                    type="text"
                                                    name="frontElectrifie"
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.frontElectrifie}
                                                    onChange={formik.handleChange}
                                                />
                                                <span>hab./lamp.</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="divider"></div>
                                    <div className="home-inner_body_parameters_content_item title">
                                        <div className="home-map-parameters_item_left">
                                            <h3>Définition du rayon frontalier</h3>
                                        </div>
                                        <div className="home-inner_body_parameters_content_item_right">
                                            <Tooltip title="Définir un rayon frontalier à partir de la frontière togolaise. Ce rayon s'applique à toutes les régions frontalières. " placement="top" arrow>
                                                <InfoOutlinedIcon className="info" />
                                            </Tooltip>
                                        </div>
                                        <div className="home-inner_body_parameters_content_item_full slider">
                                            <p>{SliderValue} km</p>
                                            <Slider
                                                name="rayonFrontalier"
                                                step={1}
                                                min={0}
                                                max={10}
                                                value={typeof SliderValue === 'number' ? SliderValue : 0}
                                                onChange={frontiereKMChangeHandler}
                                                aria-label="Small"
                                                valueLabelDisplay="auto"
                                            />
                                            <p>10 km</p>
                                        </div>
                                    </div>
                                    <div className="divider"></div>
                                    <div className="home-inner_body_parameters_content_item title">
                                        <div className="home-inner_body_parameters_content_item_left">
                                            <h3>Infrastructures dans les Villages CIZO<br/>({totalVillageCizoNbr} villages)</h3>
                                        </div>
                                        <div className="home-inner_body_parameters_content_item_right">
                                            <Tooltip title="Villages qui font partie du programme CIZO, sans les hameaux. " placement="top" arrow>
                                                <InfoOutlinedIcon className="info" />
                                            </Tooltip>
                                        </div>
                                    </div>
                                    <div className="home-inner_body_parameters_content_item">
                                        <div className="home-inner_body_parameters_content_item_left">
                                            {/*  <FormControlLabel
                                                control={
                                                    <Checkbox defaultChecked name="Savanes" />
                                                }
                                                label="Région des Savanes"
                                            />
                                            <Tooltip title="info" placement="top" arrow>
                                                <InfoOutlinedIcon className="info" />
                                            </Tooltip> */}
                                            <p>Région des Savanes<br/>({villageCizoSavanesNbr} villages)</p>
                                        </div>
                                        <div className="home-inner_body_parameters_content_item_right">
                                            <p>
                                                <input
                                                    type="text"
                                                    name="savanesVillageCizo"
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.savanesVillageCizo}
                                                    onChange={formik.handleChange}
                                                />
                                                <span>hab./lamp.</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="home-inner_body_parameters_content_item">
                                        <div className="home-inner_body_parameters_content_item_left">
                                            {/*  <FormControlLabel
                                                control={
                                                    <Checkbox defaultChecked name="Autres-régions" />
                                                }
                                                label="Autres régions"
                                            />
                                            <Tooltip title="info" placement="top" arrow>
                                                <InfoOutlinedIcon className="info" />
                                            </Tooltip> */}
                                            <p>Autres régions<br/>({villageCizoOtherNbr} villages)</p>
                                        </div>
                                        <div className="home-inner_body_parameters_content_item_right">
                                            <p>
                                                <input
                                                    type="text"
                                                    name="autresRegionsVillageCizo"
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.autresRegionsVillageCizo}
                                                    onChange={formik.handleChange}
                                                />
                                                <span>hab./lamp.</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="home-inner_body_parameters_content_item title except">
                                        <div className="home-inner_body_parameters_content_item_left">
                                            <h3>Infrastructures en Zone CIZO restante<br/>(rurale, sup. à 2km de la BT)</h3>
                                        </div>
                                        <div className="home-inner_body_parameters_content_item_right">
                                            <Tooltip title="Zone située à plus de 2km du réseau électrique basse tension actuel et à venir (cf. Projet PARE etc.),  en excluant les villages fléchés “Mini Grids”. " placement="top" arrow>
                                                <InfoOutlinedIcon className="info" />
                                            </Tooltip>
                                        </div>
                                    </div>
                                    <div className="home-inner_body_parameters_content_item">
                                        <div className="home-inner_body_parameters_content_item_left">
                                            {/* <FormControlLabel
                                                control={
                                                    <Checkbox defaultChecked name="Savanes" />
                                                }
                                                label="Région des Savanes"
                                            />
                                            <Tooltip title="info" placement="top" arrow>
                                                <InfoOutlinedIcon className="info" />
                                            </Tooltip> */}
                                            <p>Région des Savanes</p>
                                        </div>
                                        <div className="home-inner_body_parameters_content_item_right">
                                            <p>
                                                <input
                                                    type="text"
                                                    name="savanesZoneCizo"
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.savanesZoneCizo}
                                                    onChange={formik.handleChange}
                                                />
                                                <span>hab./lamp.</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="home-inner_body_parameters_content_item">
                                        <div className="home-inner_body_parameters_content_item_left">
                                            {/*  <FormControlLabel
                                                control={
                                                    <Checkbox defaultChecked name="Autres-régions" />
                                                }
                                                label="Autres régions"
                                            />
                                            <Tooltip title="info" placement="top" arrow>
                                                <InfoOutlinedIcon className="info" />
                                            </Tooltip> */}
                                            <p>Autres régions</p>
                                        </div>
                                        <div className="home-map-parameters_item_right">
                                            <p>
                                                <input
                                                    type="text"
                                                    name="autresRegionsZoneCizo"
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.autresRegionsZoneCizo}
                                                    onChange={formik.handleChange}
                                                />
                                                <span>hab./lamp.</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="divider"></div>
                                    <div className="home-inner_body_parameters_content_item title">
                                        <div className="home-inner_body_parameters_content_item_left">
                                            <h3>Infrastructures dans la zone BT<br/>(jusque 2km)</h3>
                                        </div>
                                        <div className="home-inner_body_parameters_content_item_right">
                                            <Tooltip title="Selectionnez ou non les catégories d'infrastructures à éclairer en région des Savanes ou en dehors. Attention, il s'agit ici des infrastructures présentes en dehors de les zones frontalières et CIZO et des villages CIZO. En effet, les volumes alloués à ces zones serviront déjà a éclairer des infrastructures." placement="top" arrow>
                                                <InfoOutlinedIcon className="info" />
                                            </Tooltip>
                                        </div>
                                    </div>
                                    <div className="home-inner_body_parameters_content_item">
                                        <p>Région des Savanes</p>
                                        <div className="home-inner_body_parameters_content_item_full checkbox-group">
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        defaultChecked
                                                        name="checkboxEducationSavanes"
                                                        onChange={formik.handleChange}
                                                        value={formik.values.checkboxEducationSavanes}
                                                    />
                                                }
                                                label="Éducation"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        defaultChecked
                                                        name="checkboxHealthSavanes"
                                                        onChange={formik.handleChange}
                                                        value={formik.values.checkboxHealthSavanes}
                                                    />
                                                }
                                                label="Santé"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        defaultChecked
                                                        name="checkboxWaterSavanes"
                                                        onChange={formik.handleChange}
                                                        value={formik.values.checkboxWaterSavanes}
                                                    />
                                                }
                                                label="Eau"
                                            />
                                        </div>
                                        <p>Autres régions</p>
                                        <div className="home-inner_body_parameters_content_item_full checkbox-group">
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        defaultChecked
                                                        name="checkboxEducationAutresRegions"
                                                        onChange={formik.handleChange}
                                                        value={formik.values.checkboxEducationAutresRegions}
                                                    />
                                                }
                                                label="Éducation"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        defaultChecked
                                                        name="checkboxHealthAutresRegions"
                                                        onChange={formik.handleChange}
                                                        value={formik.values.checkboxHealthAutresRegions}
                                                    />
                                                }
                                                label="Santé"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        defaultChecked
                                                        name="checkboxWaterAutresRegions"
                                                        onChange={formik.handleChange}
                                                        value={formik.values.checkboxWaterAutresRegions}
                                                    />
                                                }
                                                label="Eau"
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="home-inner_body_parameters_footer">
                                    <Button type="submit" disabled={formik.isSubmitting}>
                                        <AutoFixHigh className="magic" />
                                        <span>Calculer</span>
                                        {
                                            formik.isSubmitting ?
                                                (<CircularProgress />) : ('')
                                        }
                                    </Button>
                                </div>
                            </form>
                            <div className="home-inner_body_parameters_collapse">
                                <Tooltip title={isActiveParameters ? "Afficher" : "Cacher"} placement="top" arrow>
                                    <button onClick={handleToggleParameters}><ArrowBackIosNewIcon /></button>
                                </Tooltip>
                            </div>
                        </div>
                        {/* Search parameters end here */}
                        {/* Map start here */}

                        <div className="home-inner_body_viewer">
                            <div className="home-inner_body_viewer_content">
                                <div className="map">
                                    <div id="map">
                                        {/*<MapWrapper checkboxesTab={checkboxesTab}/>*/}
                                        <Map  
                                            subdivisionLevel={subdivisionLevel}
                                            setSubdivisionLevel={setSubdivisionLevel}
                                            selectedRegion={selectedRegion}
                                            setSelectedRegion={setSelectedRegion}
                                            selectedPrefecture={selectedPrefecture}
                                            setSelectedPrefecture={setSelectedPrefecture}
                                            selectedCommune={selectedCommune}
                                            setSelectedCommune={setSelectedCommune}

                                            layers={layers}
                                            displayedLayers={displayedLayers}
                                            mapData={mapData}
                                            latLng={countyPositions[selectedCounty].latLng}
                                            zoom={countyPositions[selectedCounty].zoom}
                                            baseLayers={baseLayers}
                                            layerSelectionButtons={layerSelectionButtons}
                                        />
                                    </div>
                                </div>
                                <div className="legend">
                                    {
                                        (selectedButton && selectedButton === 'button3') ? (
                                            <div className="difference">
                                                <h3>Lampadaires</h3>
                                                <ul>
                                                    <li>
                                                        <span className="legend-color">
                                                        </span>
                                                    </li>
                                                    <li>1 - 20</li>
                                                </ul>
                                                <ul>
                                                    <li>
                                                        <span className="legend-color">
                                                        </span>
                                                    </li>
                                                    <li>20 - 40</li>
                                                </ul>
                                                <ul>
                                                    <li>
                                                        <span className="legend-color">
                                                        </span>
                                                    </li>
                                                    <li>40 - 60</li>
                                                </ul>
                                                <ul>
                                                    <li>
                                                        <span className="legend-color">
                                                        </span>
                                                    </li>
                                                    <li>60 - 100</li>
                                                </ul>
                                                <ul>
                                                    <li>
                                                        <span className="legend-color">
                                                        </span>
                                                    </li>
                                                    <li>100 - 200</li>
                                                </ul>
                                                <ul>
                                                    <li>
                                                        <span className="legend-color">
                                                        </span>
                                                    </li>
                                                    <li>200 - 400</li>
                                                </ul>
                                                <ul>
                                                    <li>
                                                        <span className="legend-color">
                                                        </span>
                                                    </li>
                                                    <li>400 - 500</li>
                                                </ul>
                                                <ul>
                                                    <li>
                                                        <span className="legend-color">
                                                        </span>
                                                    </li>
                                                    <li>500+</li>
                                                </ul>
                                            </div>
                                        ) : (
                                            <div className="all">
                                                <h3>Hab./lamp.</h3>
                                                <ul>
                                                    <li>
                                                        <span className="legend-color">
                                                        </span>
                                                    </li>
                                                    <li>0 - 20</li>
                                                </ul>
                                                <ul>
                                                    <li>
                                                        <span className="legend-color">
                                                        </span>
                                                    </li>
                                                    <li>20 - 40</li>
                                                </ul>
                                                <ul>
                                                    <li>
                                                        <span className="legend-color">
                                                        </span>
                                                    </li>
                                                    <li>40 - 60</li>
                                                </ul>
                                                <ul>
                                                    <li>
                                                        <span className="legend-color">
                                                        </span>
                                                    </li>
                                                    <li>60 - 100</li>
                                                </ul>
                                                <ul>
                                                    <li>
                                                        <span className="legend-color">
                                                        </span>
                                                    </li>
                                                    <li>100 - 150</li>
                                                </ul>
                                                <ul>
                                                    <li>
                                                        <span className="legend-color">
                                                        </span>
                                                    </li>
                                                    <li>150 - 200</li>
                                                </ul>
                                                <ul>
                                                    <li>
                                                        <span className="legend-color">
                                                        </span>
                                                    </li>
                                                    <li>200 - 400</li>
                                                </ul>
                                                <ul>
                                                    <li>
                                                        <span className="legend-color">
                                                        </span>
                                                    </li>
                                                    <li>400+</li>
                                                </ul>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                           
                            <div className={"home-inner_body_viewer_footer" + ((resultStatusRetreive === true) ? " hideRight" : " openRight")}>
                                <div className="home-inner_body_viewer_footer_left">
                                    <Button
                                        className={(selectedButton === 'button1' ? 'active' : '')}
                                        onClick={(e) => {
                                            handleExistantClick();
                                            selectButton(e.target.attributes.info.textContent);
                                        }}
                                        info="button1">
                                        <LightModeOutlined />EXISTANT
                                    </Button>
                                    <Button
                                        disabled={disableSimulationButton}
                                        className={(selectedButton === 'button2' ? 'active' : '')}
                                        onClick={(e) => {
                                            handleSimulationClick();
                                            selectButton(e.target.attributes.info.textContent);
                                        }}
                                        info="button2"
                                    >
                                        <StackedLineChart />Simulation
                                    </Button>
                                    <Button
                                        disabled={disableSimulationButton}
                                        className={selectedButton === 'button3' ? 'active' : ''}
                                        onClick={(e) => {
                                            handleDifferenceClick();
                                            selectButton(e.target.attributes.info.textContent);
                                        }}
                                        info="button3">
                                        <CompareArrows />Construire
                                    </Button>
                                </div>
                                <div className="home-inner_body_viewer_footer_right">
                                    <Button
                                        disabled={disableSimulationButton}
                                        onClick={printCurrentPage}
                                    >
                                        <FileDownload />
                                        télécharger
                                    </Button>
                                </div>
                            </div>
                        </div>

                        {/* Search results start here */}
                        < SimulationResultComponent ref={ componentRef } simulationData={simulationData} screenSize={screenSize} />
                        {/* Search results end here */}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Home;
