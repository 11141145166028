import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Tooltip } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useSelector, useDispatch } from 'react-redux';
import React, { useState } from 'react';
import { toggleStatus } from '../../store/reducers/resultStatusReducer';
//import { useReactToPrint } from 'react-to-print';


// Color could be error, warning, info, success
const SimulationResultComponent = React.forwardRef(( {simulationData}, ref ) => {

    let dispatch = useDispatch();
    const [isActiveResults, setActiveResults] = useState(false);

    const handleToggleResults = () => {
        setActiveResults(!isActiveResults);
        dispatch(toggleStatus());
    };

    const resultData = useSelector((state) => state.simulationResult.value);


    return (
        <div className={"home-inner_body_results " + (isActiveResults ? "close" : "open")}>

            <div ref={ref} className="home-inner_body_results_content">
                <div className="home-inner_body_results_content_item">
                    <h3>Résultats</h3>
                    <p>{simulationData.lampDistribute} Lampadaires à distribuer</p>
                </div>
                <div className="home-inner_body_results_content_item title">
                    <div className="home-inner_body_results_content_item_left">
                        <h3>Total</h3>
                        <Tooltip title="Total des lampadaires à déployer dans toutes les zones" placement="top" arrow>
                            <InfoOutlinedIcon className="info" />
                        </Tooltip>
                    </div>
                    <div className="home-inner_body_results_content_item_right">
                        <p>
                            <span className={(resultData.totalLampadaires > simulationData.lampDistribute ? "over" : "great")}>{resultData.totalLampadaires}</span>
                            Lampadaires
                        </p>
                    </div>
                </div>
                <div className="divider"></div>
                <div className="home-inner_body_results_content_item title">
                    <div className="home-inner_body_results_content_item_left">
                        <h3>Frontières total</h3>
                        <Tooltip title="Total des lampadaires à déployer dans les régions frontalières" placement="top" arrow>
                            <InfoOutlinedIcon className="info" />
                        </Tooltip>
                    </div>
                    <div className="home-inner_body_results_content_item_right">
                        <p>
                            <span>{resultData.totalFrontiere}</span>
                            Lampadaires
                        </p>
                    </div>
                </div>
                <div className="home-inner_body_results_content_item">
                    <ul>
                        <li>
                            <span>Frontière Savanes</span>
                            <Tooltip title="La frontière des Savanes englobe toutes les frontières que la région des Savanes partage avec le Ghana, le Bénin et le Burkina Faso. " placement="top" arrow>
                                <InfoOutlinedIcon className="info" />
                            </Tooltip></li>
                        <li>
                            {simulationData.frontSavanes} hab./lamp.
                        </li>
                    </ul>
                    <ul>
                        <li>
                            {resultData.totalFrontiereSavanes}
                        </li>
                        <li>
                            Lampadaires
                        </li>
                    </ul>
                </div>
                <div className="home-inner_body_results_content_item">
                    <ul>
                        <li>
                            <span>Frontière Ouest</span>
                            <Tooltip title="La frontière Ouest englobe les frontières de Centrale, Plateaux, Kara et Maritime partagées avec le Ghana." placement="top" arrow>
                                <InfoOutlinedIcon className="info" />
                            </Tooltip></li>
                        <li>
                            {simulationData.frontOuest} hab./lamp.
                        </li>
                    </ul>
                    <ul>
                        <li>
                            {resultData.totalFrontiereOuest}
                        </li>
                        <li>
                            Lampadaires
                        </li>
                    </ul>
                </div>
                <div className="home-inner_body_results_content_item">
                    <ul>
                        <li>
                            <span>Frontière Est</span>
                            <Tooltip title="La frontière Est englobe les frontières de la région Centrale et de la Kara, communes avec le Bénin." placement="top" arrow>
                                <InfoOutlinedIcon className="info" />
                            </Tooltip></li>
                        <li>
                            {simulationData.frontEst} hab./lamp.
                        </li>
                    </ul>
                    <ul>
                        <li>
                            {resultData.totalFrontiereEst}
                        </li>
                        <li>
                            Lampadaires
                        </li>
                    </ul>
                </div>
                <div className="home-inner_body_results_content_item">
                    <ul>
                        <li>
                            <span>Frontière Sud-est</span>
                            <Tooltip title="La frontière Sud-Est englobe les frontières de la région des Plateaux et Maritime communes avec le Bénin." placement="top" arrow>
                                <InfoOutlinedIcon className="info" />
                            </Tooltip></li>
                        <li>
                            {simulationData.frontSudEst} hab./lamp.
                        </li>
                    </ul>
                    <ul>
                        <li>
                            {resultData.totalFrontiereSudEst}
                        </li>
                        <li>
                            Lampadaires
                        </li>
                    </ul>
                </div>
                <div className="home-inner_body_results_content_item">
                    <ul>
                        <li>
                            <span>Frontière Electrifiée</span>
                            <Tooltip title="La frontière électrifié englobe les zones a moins de 2 km du résau éléctrique basse tension" placement="top" arrow>
                                <InfoOutlinedIcon className="info" />
                            </Tooltip></li>
                        <li>
                            {simulationData.frontElectrifie} hab./lamp.
                        </li>
                    </ul>
                    <ul>
                        <li>
                            {resultData.totalFrontiereElectrifie}
                        </li>
                        <li>
                            Lampadaires
                        </li>
                    </ul>
                </div>
                <div className="divider"></div>
                <div className="home-inner_body_results_content_item title">
                    <div className="home-inner_body_results_content_item_left">
                        <h3>Villages CIZO total</h3>
                        <Tooltip title="Villages qui font partie du programme CIZO, sans les hameaux. " placement="top" arrow>
                            <InfoOutlinedIcon className="info" />
                        </Tooltip>
                    </div>
                    <div className="home-inner_body_results_content_item_right">
                        <p>
                            <span>{resultData.totalVillageCiso}</span>
                            Lampadaires
                        </p>
                    </div>
                </div>
                <div className="home-inner_body_results_content_item">
                    <ul>
                        <li>
                            <span>Regions des savanes</span>
                        </li>
                        <li>
                            {simulationData.savanesVillageCizo} hab./lamp.
                        </li>
                    </ul>
                    <ul>
                        <li>
                            {resultData.totalVillageCisoSavanes}
                        </li>
                        <li>
                            Lampadaires
                        </li>
                    </ul>
                </div>
                <div className="home-inner_body_results_content_item">
                    <ul>
                        <li>
                            <span>Autres régions</span>
                        </li>
                        <li>
                            {simulationData.autresRegionsVillageCizo} hab./lamp.
                        </li>
                    </ul>
                    <ul>
                        <li>
                            {resultData.totalVillageCisoAutresRegions}
                        </li>
                        <li>
                            Lampadaires
                        </li>
                    </ul>
                </div>
                <div className="divider"></div>
                <div className="home-inner_body_results_content_item title">
                    <div className="home-inner_body_results_content_item_left">
                        <h3>Zone CIZO total</h3>
                        <Tooltip title="Zone située à plus de 2km du réseau électrique basse tension, en excluant les villages “Mini Grids” et les villages prochainement électrifiés par la CEET. " placement="top" arrow>
                            <InfoOutlinedIcon className="info" />
                        </Tooltip>
                    </div>
                    <div className="home-inner_body_results_content_item_right">
                        <p>
                            <span>{resultData.totalZoneCiso}</span>
                            Lampadaires
                        </p>
                    </div>
                </div>
                <div className="home-inner_body_results_content_item">
                    <ul>
                        <li>
                            <span>Regions des savanes</span>
                        </li>
                        <li>
                            {simulationData.savanesZoneCizo} hab./lamp.
                        </li>
                    </ul>
                    <ul>
                        <li>
                            {resultData.totalZoneCisoSavanes}
                        </li>
                        <li>
                            Lampadaires
                        </li>
                    </ul>
                </div>
                <div className="home-inner_body_results_content_item">
                    <ul>
                        <li>
                            <span>Autres régions</span>
                        </li>
                        <li>
                            {simulationData.autresRegionsZoneCizo} hab./lamp.
                        </li>
                    </ul>
                    <ul>
                        <li>
                            {resultData.totalZoneCisoAutresRegions}
                        </li>
                        <li>
                            Lampadaires
                        </li>
                    </ul>
                </div>
                <div className="divider"></div>
                <div className="home-inner_body_results_content_item title">
                    <h3>Infrastructures non-éclairés hors CIZO total</h3>
                    <Tooltip title="Infrastructures sociales non électrifiées situées en dehors des zones éligibles au programme CIZO" placement="top" arrow>
                        <InfoOutlinedIcon className="info" />
                    </Tooltip>
                </div>

                <div className="no-light-wrap">
                    <p>Région des savanes</p>
                    <div className="home-inner_body_results_content_item no-light">
                        <ul>
                            <li>
                                <span>Education</span>
                            </li>
                        </ul>
                        <ul>
                            <li>
                                {resultData.totalInfraHorsCisoEducationSavanes}
                            </li>
                            <li>
                                Lampadaires
                            </li>
                        </ul>
                    </div>
                    <div className="home-inner_body_results_content_item no-light">
                        <ul>
                            <li>
                                <span>Santé</span>
                            </li>
                        </ul>
                        <ul>
                            <li>
                                {resultData.totalInfraHorsCisoSanteSavanes}
                            </li>
                            <li>
                                Lampadaires
                            </li>
                        </ul>
                    </div>
                    <div className="home-inner_body_results_content_item no-light">
                        <ul>
                            <li>
                                <span>Points d'eau</span>
                            </li>
                        </ul>
                        <ul>
                            <li>
                                {resultData.totalInfraHorsCisoEauSavanes}
                            </li>
                            <li>
                                Lampadaires
                            </li>
                        </ul>
                    </div>
                </div>

                {/*   les infrastructeurs des Autres regions start here */}

                <div className="no-light-wrap">
                    <p>Autres régions</p>
                    <div className="home-inner_body_results_content_item no-light">
                        <ul>
                            <li>
                                <span>Education</span>
                            </li>
                        </ul>
                        <ul>
                            <li>
                                {resultData.totalInfraHorsCisoEducationAutresRegions}
                            </li>
                            <li>
                                Lampadaires
                            </li>
                        </ul>
                    </div>
                    <div className="home-inner_body_results_content_item no-light">
                        <ul>
                            <li>
                                <span>Santé</span>
                            </li>
                        </ul>
                        <ul>
                            <li>
                                {resultData.totalInfraHorsCisoSanteAutresRegions}
                            </li>
                            <li>
                                Lampadaires
                            </li>
                        </ul>
                    </div>
                    <div className="home-inner_body_results_content_item no-light">
                        <ul>
                            <li>
                                <span>Points d'eau</span>
                            </li>
                        </ul>
                        <ul>
                            <li>
                                {resultData.totalInfraHorsCisoEauAutresRegions}
                            </li>
                            <li>
                                Lampadaires
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            {/*  <div className="home-inner_body_results_footer">
                
                <Button onClick={printDocument}>
                    <FileDownload />
                    télécharger
                </Button>
            </div> */}
            <div className="home-inner_body_results_collapse">
                <Tooltip title={isActiveResults ? "Afficher" : "Cacher"} placement="top" arrow>
                    <button onClick={handleToggleResults}><ArrowForwardIosIcon /></button>
                </Tooltip>
            </div>
        </div >
    )
});

export default SimulationResultComponent;