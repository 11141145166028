import { configureStore } from '@reduxjs/toolkit';
import snackBarReducer from './reducers/snackBarReducer';
import userReducer from './reducers/userReducer';
import layerReducer from './reducers/layerReducer';
import simulationResultReducer from './reducers/simulationResultReducer';
import resultStatusReducer from './reducers/resultStatusReducer';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { combineReducers } from 'redux';

const persistConfig = {
    key: 'root',
    storage: storage,
    whitelist: ['user'],
}

const reducers = combineReducers({
    snackbar: snackBarReducer,
    user: userReducer,
    layersSimulated: layerReducer,
    simulationResult: simulationResultReducer,
    resultStatus: resultStatusReducer
})

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
    reducer: persistedReducer,
});

/* export default configureStore({
    reducer: {
        snackbar: snackBarReducer,
        user: userReducer,
        layersSimulated: layerReducer,
        simulationResult: simulationResultReducer,
        resultStatus: resultStatusReducer
    },
}) */

export default store;