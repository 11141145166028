import React from 'react';
import './about.scss';
import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';

const About = () => {
    return (
        <div>
            <Header />
            <div className="about">
                <div className="about-inner">
                    <h1>À propos</h1>
                    <p>
                        Dans le cadre du Plan Prioritaire 2020-2025, le Gouvernement de la République Togolaise souhaite le déploiement massif et harmonieux d'infrastructures sur l’ensemble du territoire, afin de permettre un développement accéléré et équilibré. Le projet CIZO-EP, qui vise au déploiement de plus de 50 000 lampadaires solaires dans les zones les moins éclairées du Togo a été signé avec Sunna Design, société française et l’un des leaders mondiaux de l'éclairage public solaire.
                    </p>
                    <p>
                        Le projet est porté par l’intermédiaire de l’Agence Togolaise d’Électrification Rurale et des Énergies Renouvelables (AT2ER) et dirigé par le Ministère Délégué chargé de l’Energie et des Mines (MDEM) ainsi que le Ministère de l’Economie Numérique et la Transformation Digitale (MENTD).
                    </p>
                    <p>
                        S’inscrivant dans le cadre du programme d’électrification rurale CIZO (« allumer » en langue mina), le projet CIZO-EP consiste en l’un des plus importants déploiements de lampadaires solaires du continent africain. Il innove en se focalisant non pas sur les axes routiers et les communautés urbaines mais sur les communautés rurales, frontalières et moins équipées.
                    </p>
                    <p>
                        Un déploiement dans les localités prioritaires, identifiées et préalablement étudiées via une étude inédite de recensement des infrastructures rurales, assure un impact économique et social mesurable de chaque point lumineux sur les populations. Le simulateur CIZO-EP intervient comme un outil d’aide à la décision qui permet aux acteurs gouvernementaux d'évaluer des options de la distribution de lampadaires solaires sur le territoire national.
                    </p>
                    <p>
                        À partir d’un ratio de habitants par lampadaire, un rayon frontalier et une clé de répartition défini par l’utilisateur, le simulateur calcule et affiche les lampadaires sur une carte et une table nécessaires pour assurer l’accès universel à l’électricité.
                    </p>
                    <p>La clé de répartition peut être définie pour 04 zones :</p>
                    <ul>
                        <li>
                            Zone frontalière en 05 parties : Savanes, Est Nord, Est Sud, Ouest et la zone frontalière électrifiée.
                        </li>
                        <li>
                            Les villages CIZO : 788 villages prioritaires identifiés par l’AT2ER.
                        </li>
                        <li>
                            Les infrastructures : Les écoles, centres de soin et points d’eau restants, c’est à dire non éclairées et en zone électrifiée (&#60; 2 km du réseau électrique basse tension) et hors de la zone frontalière. Attention: les lampadaires alloués à la Zone Frontalière et les Villages CIZO seront également couverts avec les lampadaires alloués pour ces zones respectivement.
                        </li>
                        <li>
                            La zone CIZO : La zone située à plus de 2 km du réseau électrique BT, futur et actuel, et 1km des futurs mini réseaux.
                        </li>
                    </ul>
                    <p>
                        Les résultats sont calculés à partir des données collectées dans le cadre du Projet de recensement des infrastructures sociales et économiques (PRISE) et à partir de la base de données High Resolution Density Map par Meta, elle-même basée sur les données du Recensement Générale de la Population et de l’Habitat de 2011 et l’analyse de données complémentaires (statistique, images satellitaires).
                    </p>
                    <p>
                        Sunna Design est un pionnier et leader de l’éclairage public solaire et de la gestion de l’énergie pour les applications autonomes connectées. L’ensemble des offres de Sunna Design sont connectées et digitales, permettant ainsi de développer des services innovants et de concevoir des applications IoT (Internet of Things).
                    </p>
                    <p>
                        Cette plateforme a été développée par Mitsio Motu, société spécialisée dans la collecte, l’analyse de données et le développement de solutions numériques visant au développement des services essentiels. Opérant depuis la France et l’Afrique de l’Ouest. Mitsio Motu conseille les acteurs publics, privés et non gouvernementaux dans leurs stratégies de financement, développement et déploiement, d’opérations et constitue l’une des rares sociétés dans le domaine a combiner une très forte expertise opérationnelle à des analyses et solutions numériques complexes.
                    </p>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default About;